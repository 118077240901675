import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { arrange, filter } from "hooks/use-array"

//
//  List of team member IDs in order of presentation
//
const byRole = new Set()
byRole
  .add("wroyan")
  .add("kha")
  .add("amigon")
  .add("plo")
  .add("lburrell")
  .add("ckim")
  .add("jreising")
  .add("jchan")
  .add("isharih")
  .add("lmeintjes")
  .add("skavic")

/**
 *  Get list of team members
 *
 *  @return {array}
 */
export default function useTeam() {
  const [state, setState] = React.useState(null)

  const query = useStaticQuery(graphql`
    query TeamQuery {
      allTeamJson {
        nodes {
          id
          name
          role
          img {
            childImageSharp {
              gatsbyImageData
            }
          }
          bio
        }
      }
    }
  `).allTeamJson.nodes

  React.useEffect(() => {
    const list = arrange(filter(query, [...byRole]), [...byRole])
    setState(list)
  }, [query])

  return state
}
