import React from "react"
import Clamp from "components/clamp"
import Hero from "components/hero"
import Team from "components/team"
import Text from "components/text"

export default () => {
  return (
    <>
      <Hero>
        GPI Capital places a high value on collaboration and a team-oriented
        philosophy
      </Hero>
      <Clamp inset>
        <h1>Our team</h1>
        <Text type="lede">
          GPI Capital’s senior Partners have been investing together across the
          same strategy for over 10 years. GPI Capital established a core set of
          operating principles and values, “OLIFE,” that promotes a
          team-oriented culture of respect and trust bolstered by intellectual
          honesty, independent thinking, intense focus and an ownership mindset.
        </Text>
        {/* <Team /> */}
      </Clamp>
    </>
  )
}
