import React from "react"
import PropTypes from "prop-types"
import { join } from "hooks/use-array"
import * as styles from "./card.module.less"

export default function Card({ className, children }) {
  return <div className={join(styles.root, className)}>{children}</div>
}

Card.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}
